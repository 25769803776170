import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Container, Card } from "react-bootstrap";
import Image from "components/Image";
import "./ImageCard.scss";

const ImageCard = ({
  className,
  imageFileName,
  imageAlt,
  header,
  subheader,
  logoFileName,
  extraInfo,
}) => {
  return (
    <Card className={clsx("image-card bg-dark text-center", className)}>
      <Image className='image' fileName={imageFileName} alt={imageAlt || header || subheader} />
      <Card.ImgOverlay className='no-padding'>
        <Container>
          <div className='intro-text'>
            <div className='intro-row'>
              <Image
                className='intro-logo'
                fileName={logoFileName}
                alt={imageAlt || header || subheader}
              />
              <div className='intro-heading'>{header}</div>
            </div>
            <div className='intro-lead-in'>{subheader}</div>
            {extraInfo}
          </div>
        </Container>
      </Card.ImgOverlay>
    </Card>
  );
};

ImageCard.propTypes = {
  className: PropTypes.string,
  imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  extraInfo: PropTypes.any,
  logoFileName: PropTypes.string,
};

ImageCard.defaultProps = {
  className: null,
  imageFileName: null,
  imageAlt: null,
  header: "",
  subheader: "",
  logoFileName: "",
  extraInfo: null,
};

export default ImageCard;
